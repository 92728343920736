import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin((nuxtApp) => {
    const pusher = Pusher;
    const echo = new Echo({
        broadcaster: "pusher",
        key: "1f2363b6a3f08b2b1baa",
        cluster: "us3",
        forceTLS: true,
        authorizer: (channel) => {
            return {
                authorize: async (socketId, callback) => {
                    try {
                        const client = useSanctumClient();

                        const response = await client("/api/broadcasting/auth", {
                            method: "POST",
                            body: {
                                socket_id: socketId,
                                channel_name: channel.name,
                            },
                        });

                        callback(null, response);
                    } catch (error) {
                        callback(error);
                    }
                },
            };
        },
    });

    nuxtApp.provide("pusher", pusher);
    nuxtApp.provide("echo", echo);
});
