import { default as index0pswpwjRoXMeta } from "/var/www/frontend/src/pages/account/favorites/index.vue?macro=true";
import { default as indexqK1ZEFecpMMeta } from "/var/www/frontend/src/pages/account/index.vue?macro=true";
import { default as indexaL7oZzWJLKMeta } from "/var/www/frontend/src/pages/account/likes/index.vue?macro=true";
import { default as meINNeUfJHgFMeta } from "/var/www/frontend/src/pages/account/likes/me.vue?macro=true";
import { default as likesp8mpCwmFDpMeta } from "/var/www/frontend/src/pages/account/likes.vue?macro=true";
import { default as indexgA1vIwF6mDMeta } from "/var/www/frontend/src/pages/account/messages/index.vue?macro=true";
import { default as indexgT9Wi5K3GJMeta } from "/var/www/frontend/src/pages/account/settings/index.vue?macro=true";
import { default as accountgMtbtgqzVUMeta } from "/var/www/frontend/src/pages/account.vue?macro=true";
import { default as indexJD0HW3ZWspMeta } from "/var/www/frontend/src/pages/catalog/index.vue?macro=true";
import { default as indexyFrukAV3sGMeta } from "/var/www/frontend/src/pages/donation/index.vue?macro=true";
import { default as email_45verification_45requiredOVXAQtYKVDMeta } from "/var/www/frontend/src/pages/email-verification-required.vue?macro=true";
import { default as index3ytxGF5LcOMeta } from "/var/www/frontend/src/pages/index.vue?macro=true";
import { default as loginbNubkjJAROMeta } from "/var/www/frontend/src/pages/login.vue?macro=true";
import { default as _91slug_93ne5JsBbbUpMeta } from "/var/www/frontend/src/pages/media/[slug].vue?macro=true";
import { default as indexdIPRP4RtmtMeta } from "/var/www/frontend/src/pages/media/index.vue?macro=true";
import { default as _91slug_93IlvXIb3PneMeta } from "/var/www/frontend/src/pages/pages/[slug].vue?macro=true";
import { default as _91step_93GwPAORXmPSMeta } from "/var/www/frontend/src/pages/profile-completion/[step].vue?macro=true";
import { default as profile_45onboardinggwBPMLgzNOMeta } from "/var/www/frontend/src/pages/profile-onboarding.vue?macro=true";
import { default as _91slug_93ace2fzqHgsMeta } from "/var/www/frontend/src/pages/profile/[slug].vue?macro=true";
import { default as reset_45passwordem7GQPzDbtMeta } from "/var/www/frontend/src/pages/reset-password.vue?macro=true";
import { default as sign_45upFibHPYpOFsMeta } from "/var/www/frontend/src/pages/sign-up.vue?macro=true";
import { default as indexKYwkCY5Tl3Meta } from "/var/www/frontend/src/pages/support/index.vue?macro=true";
import { default as indexv3lJHomVseMeta } from "/var/www/frontend/src/pages/suspended/index.vue?macro=true";
import { default as indexOwHrLgwikMMeta } from "/var/www/frontend/src/pages/swipes/index.vue?macro=true";
import { default as testHKbukiTgSMMeta } from "/var/www/frontend/src/pages/test.vue?macro=true";
import { default as indexyqAVHhQduSMeta } from "/var/www/frontend/src/pages/verified/index.vue?macro=true";
import { default as verify_45emailmc2N54WP5LMeta } from "/var/www/frontend/src/pages/verify-email.vue?macro=true";
export default [
  {
    name: accountgMtbtgqzVUMeta?.name,
    path: "/account",
    meta: accountgMtbtgqzVUMeta || {},
    component: () => import("/var/www/frontend/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-favorites",
    path: "favorites",
    component: () => import("/var/www/frontend/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/frontend/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: likesp8mpCwmFDpMeta?.name,
    path: "likes",
    component: () => import("/var/www/frontend/src/pages/account/likes.vue").then(m => m.default || m),
    children: [
  {
    name: "account-likes",
    path: "",
    component: () => import("/var/www/frontend/src/pages/account/likes/index.vue").then(m => m.default || m)
  },
  {
    name: "account-likes-me",
    path: "me",
    component: () => import("/var/www/frontend/src/pages/account/likes/me.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-messages",
    path: "messages",
    component: () => import("/var/www/frontend/src/pages/account/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "settings",
    component: () => import("/var/www/frontend/src/pages/account/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: indexJD0HW3ZWspMeta || {},
    component: () => import("/var/www/frontend/src/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "donation",
    path: "/donation",
    component: () => import("/var/www/frontend/src/pages/donation/index.vue").then(m => m.default || m)
  },
  {
    name: "email-verification-required",
    path: "/email-verification-required",
    meta: email_45verification_45requiredOVXAQtYKVDMeta || {},
    component: () => import("/var/www/frontend/src/pages/email-verification-required.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginbNubkjJAROMeta || {},
    component: () => import("/var/www/frontend/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    meta: _91slug_93ne5JsBbbUpMeta || {},
    component: () => import("/var/www/frontend/src/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: "media",
    path: "/media",
    meta: indexdIPRP4RtmtMeta || {},
    component: () => import("/var/www/frontend/src/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: "pages-slug",
    path: "/pages/:slug()",
    component: () => import("/var/www/frontend/src/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "profile-completion-step",
    path: "/profile-completion/:step()",
    meta: _91step_93GwPAORXmPSMeta || {},
    component: () => import("/var/www/frontend/src/pages/profile-completion/[step].vue").then(m => m.default || m)
  },
  {
    name: "profile-onboarding",
    path: "/profile-onboarding",
    meta: profile_45onboardinggwBPMLgzNOMeta || {},
    component: () => import("/var/www/frontend/src/pages/profile-onboarding.vue").then(m => m.default || m)
  },
  {
    name: "profile-slug",
    path: "/profile/:slug()",
    component: () => import("/var/www/frontend/src/pages/profile/[slug].vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordem7GQPzDbtMeta || {},
    component: () => import("/var/www/frontend/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upFibHPYpOFsMeta || {},
    component: () => import("/var/www/frontend/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/var/www/frontend/src/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "suspended",
    path: "/suspended",
    component: () => import("/var/www/frontend/src/pages/suspended/index.vue").then(m => m.default || m)
  },
  {
    name: "swipes",
    path: "/swipes",
    meta: indexOwHrLgwikMMeta || {},
    component: () => import("/var/www/frontend/src/pages/swipes/index.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/www/frontend/src/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "verified",
    path: "/verified",
    meta: indexyqAVHhQduSMeta || {},
    component: () => import("/var/www/frontend/src/pages/verified/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-email",
    path: "/verify-email",
    component: () => import("/var/www/frontend/src/pages/verify-email.vue").then(m => m.default || m)
  }
]