import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

type ModalState = 'auth' | 'forgotten' | 'logout';

interface useAuthModalProps {
    isModalOpen: Ref<boolean>;
    openModal: () => void;
    closeModal: () => void;
    toggleModal: () => void;
    state: Ref<ModalState>;
    changeState: () => void;
}

export const useAuthModal = defineStore('authModal', (): useAuthModalProps => {
  const isModalOpen = ref(false);
  const state = ref('auth');
  
  const closeModal = (): void => {
    isModalOpen.value = false;
  }

  const toggleModal = (): void => {
    isModalOpen.value = !isModalOpen.value;
  }

  const changeState = (value: ModalState) => {
    state.value = value;
  }
  const openModal = (): void => {
    isModalOpen.value = true;
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal,
    state,
    changeState,
  };
});